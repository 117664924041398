import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subject, takeUntil } from 'rxjs';
import { TransformNetworkToDefaultTypePipe } from '../../pipes/transform-network-to-default-type.pipe';


@Component({
  selector: 'exchange-select',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatSelectModule, ReactiveFormsModule, FormsModule, NgxMatSelectSearchModule, TransformNetworkToDefaultTypePipe],
  templateUrl: './exchange-select.component.html',
  styleUrls: ['./exchange-select.component.scss']
})
export class ExchangeSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('matSelect') matSelect!: MatSelect;
  @Input() inputPanelClass = 'exchange-select-panel-class';

  @Input() set selectDataInput(data: any) {
    this.selectArray = data;
    this.initialArray = data;
  }
  @Input() set selectedDirectionInput(data: any) {
    this.selectedDirection = data;
  }
  @Output() selectValue = new EventEmitter<any>();
  initialArray: any;
  selectArray: any;
  selectedDirection: any;
  controlSelect: FormControl = new FormControl();
  controlSearch: FormControl = new FormControl();

  private unsubscribe$ = new Subject<boolean>();

  ngOnInit(): void {
    this.controlSearch.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: string) => {
        this.filter(value.toLowerCase());
      },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    if (this.matSelect) {
      this.matSelect.openedChange
        .subscribe((opened) => {
          if (!opened) {
            this.initialArray = this.closeOpenedNetworkSelects();
          }
        });
    }
  }

  chooseCurrency(data: any): void {
    const selectedNetwork: string = this.selectedDirection.selectData.merchant;
    let currentDirection = data.currencyDirections.find((item: any) => item.selectData.merchant === selectedNetwork);
    if (!currentDirection) {
      currentDirection = data.currencyDirections[0];
    }
    this.selectValue.emit(currentDirection);
  }

  chooseNetwork(data: any, network: string): void {
    const currentDirection = data.currencyDirections.find((item: any) => item.selectData.merchant === network);
    this.selectValue.emit(currentDirection);
    this.matSelect.close();
  }

  private closeOpenedNetworkSelects(): any {
    const array = this.initialArray = this.initialArray.map((item: any) => ({
      ...item,
      selectData: {
        ...item.selectData,
        openNetworkSelect: false
      },
    }));
    return array;
  }

  private filter(search: string): void {
    if (search) {
      const filteredArray = this.initialArray.filter(
        (element: any) =>
          element.selectData.currency.toLowerCase().indexOf(search) > -1 ||
          element.selectData.currencyName.toLowerCase().indexOf(search) > -1 ||
          element.selectData.merchant.toLowerCase().indexOf(search) > -1
      );
      this.selectArray = filteredArray;
    } else {
      this.selectArray = this.initialArray;
    }
  }
}
