import { RateCommon, FERequest, DirectionData } from 'src/app/api/fast-exchange/fast-exchange.model';
import { FastExchangeStoreState } from './fast-exchange.state';
import { createSelector } from '@ngrx/store';

export namespace FastExchangeSelectors {
  export const selectFastExchange = (state: { fastExchange: FastExchangeStoreState }) => state.fastExchange;

  export const selectCurrentRate = createSelector(selectFastExchange, ({ currentRate }: FastExchangeStoreState): RateCommon | null => currentRate);
  export const selectCommissionData = createSelector(selectFastExchange, ({ commissionData }: FastExchangeStoreState): any => commissionData);
  export const selectCommissionError = createSelector(selectFastExchange, ({ commissionError }: FastExchangeStoreState): any => commissionError);
  export const selectFEResponse = createSelector(selectFastExchange, ({ responseFERequest }: FastExchangeStoreState): FERequest | null => responseFERequest);
  export const selectFEFailed = createSelector(selectFastExchange, ({ responseFailed }: FastExchangeStoreState): boolean => responseFailed);
  export const selectIsLoadingInfo = createSelector(selectFastExchange, ({ isLoadingInfo }: FastExchangeStoreState): boolean => isLoadingInfo);
  export const selectIsConfirmPayment = createSelector(selectFastExchange, ({ isConfirmPayment }: FastExchangeStoreState): boolean => isConfirmPayment);
  export const selectDepositParams = createSelector(selectFastExchange, ({ depositParams }: FastExchangeStoreState): any => depositParams);
  export const selectDirections = createSelector(selectFastExchange, ({ directions }: FastExchangeStoreState): DirectionData[]  => directions);
  export const selectIsDirectionPresent = createSelector(selectFastExchange, ({ isDirectionPresent }: FastExchangeStoreState): boolean => isDirectionPresent);
  export const selectIsLoadingDirection = createSelector(selectFastExchange, ({ isLoadingDirection }: FastExchangeStoreState): boolean => isLoadingDirection);
}
