import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'exchange',
    loadChildren: () => import('./modules/pages/exchange-page/exchange-page.module').then(m => m.ExchangePageModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'cabinet',
    loadChildren: () => import('./modules/pages/cabinet/cabinet.module').then(m => m.CabinetModule),
  },
  {
    path: 'contacts',
    loadChildren: () => import('./modules/pages/contact-page/contact-page.module').then(m => m.ContactPageModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./modules/pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
  },
  {
    path: 'aml-check',
    loadChildren: () => import('./modules/pages/aml-check/aml-check.module').then(m => m.AmlCheckModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/pages/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: '404',
    loadChildren: () => import('./modules/pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { useHash: false, scrollPositionRestoration: 'enabled' }
  )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
