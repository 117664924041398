import { DirectionData, DirectionsMerchantData } from 'src/app/api/fast-exchange/fast-exchange.model';
import { CurrencyType } from '../enums/fast-exchange.enum';
import { SelectData } from '../models/fast-exchange/exchange.model';


export class UtilsTransformDirections {
  static tranformDirectionData(data: DirectionData[]): any {
    if (data && data[0]?.merchant_currency) {
      const merchantCurrency: (DirectionData)[] = data.map((item: DirectionData) => this.convertFromMerchantForSelect(item));
      const grouppedArray = this.grouppedDirection(merchantCurrency);
      return grouppedArray;
    }
    return data;
  }


  static grouppedDirection(data: any): void {
    const initialArray = data;
    const uniqueCurrency = new Set<string>();
    const mergedArray: any[][] = [];

    initialArray.forEach((obj: any) => {
      const { selectData }: SelectData  = obj;
      if (!uniqueCurrency.has(selectData.currency) || !(selectData.sub_merchant === 'CRYPTO' || selectData.sub_merchant === 'MANUAL_CRYPTO')) {
        uniqueCurrency.add(selectData.currency);
        mergedArray.push([ obj ]);
      } else {
        const index = mergedArray.findIndex((arr) => arr[0].selectData.currency === selectData.currency);
        mergedArray[index].push( obj );
      }
    });
    const updateArray = mergedArray.map((array: any) => {
      const newObject = {
        currencyDirections: array,
        selectData: {
          ...array[0].selectData,
          merchantList: array.map((currency: any) => { return currency.selectData.merchant;}),
          apiCodes: array.map((currency: any) => { return currency.merchant_currency.api_code;})
        }
      };
      return newObject;
    });
    const groupTo = this.grouppedToDirections(updateArray);
    return groupTo;
  }

  static grouppedToDirections(data: any[]): any {
    const initialArray = data;
    const newArray: any[] = [];
    initialArray.map((obj) => {
      newArray.push(this.saveInCurrencyDirection(obj));
    });
    return newArray;
  }

  static handleDirectins(data: any): any {
    const uniqueCurrency = new Set<string>();
    const mergedArray: any[][] = [];

    data.exchange_direction_data.map((dir: any) => {
      const { selectData }: SelectData = dir;
      if (!uniqueCurrency.has(selectData.currency) || !(selectData.sub_merchant === 'CRYPTO' || selectData.sub_merchant === 'MANUAL_CRYPTO')) {
        uniqueCurrency.add(selectData.currency);
        mergedArray.push([ dir ]);
      } else {
        const index = mergedArray.findIndex((arr) => arr[0].selectData.currency === selectData.currency);
        mergedArray[index].push( dir );
      }
    });
    const qqqArray = mergedArray.map((qqq: any) => {
      const q1 = {
        currencyDirections: qqq,
        selectData: {
          ...qqq[0].selectData,
          merchantList: qqq.map((currency: any) => { return currency.selectData.merchant;}),
          apiCodes: qqq.map((currency: any) => { return currency?.api_code || currency?.to_merchant_currency.api_code;})
        }
      };
      return q1;
    });

    const newData = {
      ...data,
      exchange_direction_data: qqqArray
    };
    return newData;
  }

  static saveInCurrencyDirection(data: any): any {
    const newArray: any = [];
    data.currencyDirections.map((element: any) => {
      newArray.push(this.handleDirectins(element));
    });
    const newObject = {
      ...data,
      currencyDirections: newArray
    };
    return newObject;
  }


  static convertFromMerchantForSelect(data: DirectionData): DirectionData & SelectData {
    const transform = {
      ...data,
      selectData: {
        id: data.merchant_currency.id,
        icon: (data.merchant_currency.currency.type === CurrencyType.FIAT) ? data.merchant_currency.merchant.symbol : data.merchant_currency.currency.symbol,
        currency: data.merchant_currency.currency.symbol,
        currencyName: data.merchant_currency.currency.name,
        merchant: data.merchant_currency.merchant.symbol,
        sub_merchant: data.merchant_currency.merchant.sub_merchant,
        openNetworkSelect: false,
        merchantList: [],
        apiCode: data.merchant_currency.api_code,
        apiCodes: []
      },
      exchange_direction_data: data.exchange_direction_data.map((item: DirectionsMerchantData) => (
        this.convertToMerchantForSelect(item))
      )
    };
    return transform;
  }

  static convertToMerchantForSelect(data: DirectionsMerchantData): DirectionsMerchantData {
    const transform = {
      ...data,
      selectData: {
        id: data.id,
        icon: (data.to_merchant_currency.currency.type === CurrencyType.FIAT) ? data.to_merchant_currency.merchant.symbol : data.to_merchant_currency.currency.symbol,
        currency: data.to_merchant_currency.currency.symbol,
        currencyName: data.to_merchant_currency.currency.name,
        merchant: data.to_merchant_currency.merchant.symbol,
        sub_merchant: data.to_merchant_currency.merchant.sub_merchant,
        openNetworkSelect: false,
        merchantList: [],
        apiCode: data.to_merchant_currency.api_code,
        apiCodes: []
      },
    };
    return transform;
  }
}
