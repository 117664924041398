<div class="flex min-w-[80vw] flex-col items-center md:min-w-[566px] md:max-w-[566px]">
  <div class="z-[2] mb-[-43px] flex h-[60px] w-[60px] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-800 sm:h-[86px] sm:w-[86px]">
    <span (click)="closeDialog()" class="flex h-[44px] w-[44px] cursor-pointer flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-300 p-[14px] sm:h-[64px] sm:w-[64px] sm:p-0">
      <img ngSrc="assets/images/svg/icons/close-icons.svg" alt="" height="24" width="24" />
    </span>
  </div>
  <div class="flex w-full flex-col overflow-y-auto max-h-[90vh] rounded-[30px] bg-main-dark-blue-300 px-[25px] pb-[25px] pt-[50px] text-[12px] sm:px-[40px] sm:pb-[40px] sm:pt-[65px] sm:text-[16px]">
    <button type="button" ngxClipboard [cbContent]="requestData.number" class="text-[12px] text-left flex items-center opacity-60 transition-all hover:opacity-100 cursor-pointer mb-4">
      {{ 'shared.request_number' | translate }} {{requestData.number}}
      <img class="ml-2" ngSrc="assets/images/svg/icons/icon-copy.svg" alt="" height="18" width="18" />
    </button>
    <div class="mb-[45px] text-center text-[24px] font-semibold sm:text-[32px]">
      {{ 'modals.status.' + (requestData.status | feRequestStatusTitle:requestData.deposit_request_status:requestData.withdraw_request_status) | translate }}
      <ng-container *ngIf="statusDepositInProgress && !statusRevoked">{{ requestData.from_currency_symbol }}!</ng-container>
      <ng-container *ngIf="statusDepositSuccess && !statusWithdrawSuccess && !statusRevoked">{{ requestData.to_currency_symbol }}!</ng-container>
    </div>
    <div class="flex flex-col gap-[24px]">
      <div class="bordred-solid flex justify-between gap-[15px] border-b border-white/20 pb-[15px]">
        <div class="flex flex-col w-1/2">
          <span class="text-white/60">{{ 'modals.status.labels.you_give' | translate }}</span>
          <span class="font-semibold">
            {{requestData.from_amount | number:('1.0-8')}}
            {{requestData.from_currency_symbol | transformCurrencyWithNetworkToToken : requestData.from_merchant_symbol : true}}
          </span>
        </div>
        <div class="flex flex-col w-1/2">
          <span class="text-white/60">{{ 'modals.status.labels.exchange_rate' | translate }}</span>
          <span class="font-semibold">{{requestData.front_rate}}</span>
        </div>
      </div>
      <div class="wrap-w-col flex justify-between flex-wrap gap-x-[15px] gap-y-[24px] max-w-[500px]">
        <div class="flex flex-col w-col">
          <span class="text-white/60">{{ 'modals.status.labels.receive' | translate }}</span>
          <span class="font-semibold">
            {{requestData.to_amount_without_commission | number:('1.0-8')}}
            {{requestData.to_currency_symbol | transformCurrencyWithNetworkToToken : requestData.to_merchant_symbol : true}}
          </span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.owner_email">
          <span class="text-white/60">Email:</span>
          <span class="font-semibold break-all">
            {{requestData.withdraw_params.owner_email | maskContactInfo:'email'}}
          </span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.telegram_nickname">
          <span class="text-white/60">Telegram:</span>
          <span class="font-semibold break-all">
            {{requestData.withdraw_params.telegram_nickname}}
          </span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.deposit_params.owner_card_number">
          <span class="text-white/60">{{ 'page_exchange.info_fields.owner_card_number' | translate }}</span>
          <span class="font-semibold">{{requestData.deposit_params.owner_card_number}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.crypto_address">
          <span class="text-white/60">{{ 'modals.status.labels.on_the_wallet' | translate }}</span>
          <span class="font-semibold break-all">
            {{requestData.withdraw_params.crypto_address}}
            {{requestData.withdraw_params?.crypto_is_need_tag_address ? (' : ' + requestData?.withdraw_params?.crypto_tag_address) : ''}}
          </span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.crypto_alias">
          <span class="text-white/60">{{ 'page_exchange.info_fields.crypto_alias' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.crypto_alias}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_number">
          <span class="text-white/60">{{ 'modals.status.labels.on_the_card' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_number}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_alias">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_alias' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_alias}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_holder_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_holder_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_holder_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_account_number">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_account_number' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_account_number}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_bank_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_bank_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_bank_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_email">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_email' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_email}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_phone">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_phone' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_phone}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_telegram">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_telegram' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_telegram}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.card_country">
          <span class="text-white/60">{{ 'page_exchange.info_fields.card_country' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_country}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_account">
          <span class="text-white/60">{{ 'modals.status.labels.on_the_fiat' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_account}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_card_number">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_card_number' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_card_number}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_alias">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_alias' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_alias}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_holder_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_holder_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_holder_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_first_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_first_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_first_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_last_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_last_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_last_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_bank_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_bank_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_bank_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_bank_code">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_bank_code' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_bank_code}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_bank_account">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_bank_account' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_bank_account}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_bank_card_no">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_bank_card_no' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_bank_card_no}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_branch_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_branch_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_branch_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_account_number">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_account_number' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_account_number}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_email">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_email' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_email}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_phone">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_phone' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_phone}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_telegram">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_telegram' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_telegram}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_country">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_country' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_country}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.fiat_city">
          <span class="text-white/60">{{ 'page_exchange.info_fields.fiat_city' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_city}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_alias">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_alias' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_alias}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_holder_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_holder_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_holder_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_name">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_name' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_name}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_code">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_code' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_code}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_account_number">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_account_number' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_account_number}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_email">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_email' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_email}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_phone">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_phone' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_phone}}</span>
        </div>

        <div class="flex flex-col w-col" *ngIf="requestData.withdraw_params.bank_telegram">
          <span class="text-white/60">{{ 'page_exchange.info_fields.bank_telegram' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.bank_telegram}}</span>
        </div>
      </div>
    </div>
    <ul class="status-list mt-[20px]">
      <li class="flex flex-col" [class.active]="requestData.deposit_request_status === 'COMPLETED'" [class.process]="(requestData.deposit_request_status === 'IN_PROCESS' || requestData.deposit_request_status === 'PAID_UNCONFIRMED') && !(requestData.deposit_request_status === 'AUTO_REVOKED' || requestData.deposit_request_status === 'REVOKED_BY_ADMIN' || requestData.deposit_request_status === 'REVOKED_BY_ACCOUNT' || requestData.deposit_request_status === 'EXPIRED' || requestData.status === 'EXPIRED')" [class.failed]="requestData.deposit_request_status === 'AUTO_REVOKED' || requestData.deposit_request_status === 'REVOKED_BY_ADMIN' || requestData.deposit_request_status === 'REVOKED_BY_ACCOUNT' || requestData.deposit_request_status === 'EXPIRED' || requestData.status === 'EXPIRED'">
        <ng-container [ngSwitch]="requestData?.from_sub_merchant">
          <ng-container *ngSwitchCase="SubMerchant.CRYPTO">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_crypto' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">
              <ng-container *ngIf="requestData?.deposit_confirmations && requestData?.deposit_confirmations > 0; else templateWithoutDepositConfirmation">
                <ng-container  *ngIf="requestData.to_sub_merchant === SubMerchant.CRYPTO">
                  {{ 'exchange.request_status.' + ((requestData?.deposit_confirmations === 1) ? 'deposit_text_crypto_exchange_confirm_transaction' : 'deposit_text_crypto_exchange_confirm_transaction_plural') | translate :{ fromCurrency: requestData.from_currency_symbol, toCurrency: requestData.to_currency_symbol, confirm: requestData.deposit_confirmations, network: (requestData.from_merchant_symbol | transformNetworkToDefaultType: requestData.from_currency_symbol) } }}
                </ng-container>
                <ng-container  *ngIf="requestData.to_sub_merchant !== SubMerchant.CRYPTO">
                  {{ 'exchange.request_status.' + ((requestData?.deposit_confirmations === 1) ? 'deposit_text_crypto_confirm_transaction' : 'deposit_text_crypto_confirm_transaction_plural') | translate :{ currency: requestData.from_currency_symbol, confirm: requestData.deposit_confirmations, network: (requestData.from_merchant_symbol | transformNetworkToDefaultType: requestData.from_currency_symbol) } }}
                </ng-container>
              </ng-container>
              <ng-template #templateWithoutDepositConfirmation>
                {{ 'exchange.request_status.deposit_text_crypto_def' | translate: {currency: requestData?.from_currency_symbol} }}
              </ng-template>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_CRYPTO">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_crypto' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">
              <ng-container *ngIf="requestData?.deposit_confirmations && requestData?.deposit_confirmations > 0; else templateWithoutDepositConfirmation">
                <ng-container  *ngIf="requestData.to_sub_merchant === SubMerchant.CRYPTO">
                  {{ 'exchange.request_status.' + ((requestData?.deposit_confirmations === 1) ? 'deposit_text_crypto_exchange_confirm_transaction' : 'deposit_text_crypto_exchange_confirm_transaction_plural') | translate :{ fromCurrency: requestData.from_currency_symbol, toCurrency: requestData.to_currency_symbol, confirm: requestData.deposit_confirmations, network: (requestData.from_merchant_symbol | transformNetworkToDefaultType: requestData.from_currency_symbol) } }}
                </ng-container>
                <ng-container  *ngIf="requestData.to_sub_merchant !== SubMerchant.CRYPTO">
                  {{ 'exchange.request_status.' + ((requestData?.deposit_confirmations === 1) ? 'deposit_text_crypto_confirm_transaction' : 'deposit_text_crypto_confirm_transaction_plural') | translate :{ currency: requestData.from_currency_symbol, confirm: requestData.deposit_confirmations, network: (requestData.from_merchant_symbol | transformNetworkToDefaultType: requestData.from_currency_symbol) } }}
                </ng-container>
              </ng-container>
              <ng-template #templateWithoutDepositConfirmation>
                {{ 'exchange.request_status.deposit_text_crypto_def' | translate: {currency: requestData?.from_currency_symbol} }}
              </ng-template>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.CARD">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_card' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.deposit_text_card' | translate: {currency: requestData?.from_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_CARD">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_card' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.deposit_text_card' | translate: {currency: requestData?.from_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.BANK">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_bank' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.deposit_text_bank' | translate: {currency: requestData?.from_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_BANK">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_bank' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.deposit_text_bank' | translate: {currency: requestData?.from_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.FIAT">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_fiat' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.deposit_text_fiat' | translate: {currency: requestData?.from_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_FIAT">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.deposit_title_fiat' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.deposit_text_fiat' | translate: {currency: requestData?.from_currency_symbol} }}</span>
          </ng-container>
        </ng-container>
      </li>
      <li class="flex flex-col"
      [class.active]="requestData.withdraw_request_status === 'COMPLETED'"
      [class.process]="requestData.deposit_request_status === 'COMPLETED' && !(requestData.withdraw_request_status === 'COMPLETED') && !(requestData.status === 'AUTO_REVOKED' || requestData.status === 'REVOKED_BY_ADMIN' || requestData.status === 'REVOKED_BY_ACCOUNT' || requestData.status === 'EXPIRED') && !(requestData.deposit_request_status === 'AUTO_REVOKED' || requestData.deposit_request_status === 'REVOKED_BY_ADMIN' || requestData.deposit_request_status === 'REVOKED_BY_ACCOUNT' || requestData.deposit_request_status === 'EXPIRED' || requestData.status === 'EXPIRED')"
      [class.failed]="requestData.status === 'AUTO_REVOKED' || requestData.status === 'REVOKED_BY_ADMIN' || requestData.status === 'REVOKED_BY_ACCOUNT' || requestData.status === 'EXPIRED'">
        <ng-container [ngSwitch]="requestData?.to_sub_merchant">
          <ng-container *ngSwitchCase="SubMerchant.CRYPTO">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_crypto' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_crypto' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_CRYPTO">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_crypto' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_crypto' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.CARD">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_card' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_card' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_CARD">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_card' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_card' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.BANK">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_bank' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_bank' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_BANK">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_bank' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_bank' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.FIAT">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_fiat' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_fiat' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="SubMerchant.MANUAL_FIAT">
            <span class="text-[14px] font-bold sm:text-[18px]">{{ 'exchange.request_status.withdraw_title_fiat' | translate }}</span>
            <span class="text-[10px] sm:text-[14px]">{{ 'exchange.request_status.withdraw_text_fiat' | translate: {currency: requestData?.to_currency_symbol} }}</span>
          </ng-container>
        </ng-container>
      </li>
    </ul>
    <div *ngIf="(txid || txid === '') && requestData.withdraw_request_status === 'COMPLETED'" class="flex justify-between gap-[15px] mt-5">
      <button *ngIf="txid" ngxClipboard [cbContent]="txid" class="btn btn-black flex min-w-full !flex-row items-center gap-2 mt-3 break-all">
        <span>{{txid}}</span>
        <img ngSrc="assets/images/svg/icons/icon-copy.svg" alt="" height="24" width="24" />
      </button>
      <button *ngIf="txid === ''" class="btn btn-black flex min-w-full !flex-row items-center gap-2 mt-3 break-all animation-pulse">
        <span>{{ 'modals.status.labels.waiting_txid' | translate }}</span>
      </button>
    </div>
  </div>
</div>
