import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { LANG_LIST } from 'src/app/core/constants/data.contstans';
import { STORAGE_SITE_LANG } from 'src/app/core/constants/storage.constant';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @ViewChild('mobileMenu') mobileMenu!: TemplateRef<any>;
  
  controlSearch: FormControl<string> = new FormControl();
  controlLang: FormControl = new FormControl();

  isUserAuth = false;
  isMobileMenu = false;
  showSearch = false;
  langList = LANG_LIST;
  selectLang: string;

  private overlayRef!: OverlayRef;
  private config = new OverlayConfig({
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-backdrop-mobile-menu',
    scrollStrategy: this.overlay.scrollStrategies.block(),
  });
  private unsubscribe$ = new Subject<boolean>();
  constructor(
    public translateService: TranslateService,
    private overlay: Overlay, 
    private viewContainerRef: ViewContainerRef,
    private router: Router,
  ) {
    this.selectLang = this.translateService.currentLang;
    this.controlLang.setValue(this.selectLang.toUpperCase());
    this.listenerChangeLang();
    this.listenerChangeSearch();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: LangChangeEvent) => {
        this.controlLang.setValue(value.lang.toUpperCase());
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  openMobileMenu(): void {
    this.overlayRef = this.overlay.create(this.config);
    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.overlayRef.detach();
          this.isMobileMenu = false;
        },
      });
    this.overlayRef.attach(new TemplatePortal(this.mobileMenu, this.viewContainerRef));
    this.isMobileMenu = true;
  }

  closeMobileMenu(): void {
    if (this.isMobileMenu) {
      this.overlayRef.detach();
    }
  }

  openSearch(): void {
    this.showSearch = true;
  }

  closeSearch(): void {
    this.showSearch = false;
  }

  searchRequest(): void {
    if (this.controlSearch.getRawValue() && this.controlSearch.getRawValue().length > 0) {
      this.router.navigate([], { queryParams: { searchRequest: this.controlSearch.getRawValue() } });
      this.controlSearch.reset();
    }
    this.closeSearch();
    this.closeMobileMenu();
  }

  private listenerChangeLang(): void {
    this.controlLang.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (lang: string) => {
        this.controlLang.setValue(lang, { emitEvent: false });
        this.translateService.use(lang.toLowerCase());
        localStorage.setItem(STORAGE_SITE_LANG, lang);
      }
    });
  }

  private listenerChangeSearch(): void {
    this.controlSearch.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (search: string) => {
        this.controlSearch.setValue(search, { emitEvent: false });
      }
    });
  }
}
