<header class="flex h-[87px] items-center">
  <div class="container flex items-center justify-between z-40">
    <a routerLink="/" class="flex w-full max-w-[273px] lg:max-w-[351px]">
      <img class="w-full" src="assets/images/logo.png" alt="" />
    </a>

    <div class="ml-10 hidden w-full lg:flex 2xl:ml-40">
      <ul class="mr-auto flex items-center gap-10 2xl:gap-[86px]">
        <li>
          <a routerLink="/" class="text-lg hover:text-main-purple-400">{{ 'header.home' | translate }}</a>
        </li>
        <li>
          <a routerLink="/faq" class="text-lg hover:text-main-purple-400">{{ 'header.faq' | translate }}</a>
        </li>
        <li>
          <a routerLink="/contacts" class="text-lg hover:text-main-purple-400">{{ 'header.contacts' | translate }}</a>
        </li>
      </ul>
      <form class="lang-switch ml-3 mr-7">
        <mat-label class="mr-2">
          <img class="h-[18px] w-7 min-w-[28px]" [src]="'assets/images/flag-' + controlLang.getRawValue().toLowerCase() + '.png'" [alt]="controlLang.getRawValue()" />
        </mat-label>
        <mat-select class="" [formControl]="controlLang" [panelClass]="'lang-desk-panel-class'">
          <ng-container *ngFor="let lang of langList">
            <mat-option [value]="lang.value">
              <div class="flex items-center">
                <img class="mr-[7px] h-[18px] w-7 min-w-[28px]" [src]="'assets/images/flag-' + lang.value.toLowerCase() + '.png'" [alt]="lang.value" />
                <span class="text-sm leading-none text-white">{{ lang.value }}</span>
              </div>
            </mat-option>
          </ng-container>
        </mat-select>
      </form>
      <div class="relative mr-[10px] cursor-pointer">
        <div class="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-[4px] border-2 border-solid border-main-purple-500">
          <span class="flex h-full w-full flex-col items-center justify-center" (click)="openSearch()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M21 21L16.1667 16.1667M18.7778 9.88889C18.7778 14.7981 14.7981 18.7778 9.88889 18.7778C4.97969 18.7778 1 14.7981 1 9.88889C1 4.97969 4.97969 1 9.88889 1C14.7981 1 18.7778 4.97969 18.7778 9.88889Z"
                stroke="#8571FE"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
        </div>
        <div class="overlay" *ngIf="showSearch" (click)="closeSearch()"></div>
        <form (ngSubmit)="searchRequest()" class="pointer-events-none absolute right-0 top-0 z-[10] flex opacity-0" [ngClass]="{ 'pointer-events-auto !opacity-100 transition duration-200 ease-linear': showSearch }">
          <input [placeholder]="'header.search_placeholder' | translate" type="text" class="form-input-black input-search border-main-purple-500 border" [ngClass]="{ active: showSearch }" [formControl]="controlSearch"/>
          <button type="submit" class="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-r-[4px] bg-main-purple-500">
            <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M21 21L16.1667 16.1667M18.7778 9.88889C18.7778 14.7981 14.7981 18.7778 9.88889 18.7778C4.97969 18.7778 1 14.7981 1 9.88889C1 4.97969 4.97969 1 9.88889 1C14.7981 1 18.7778 4.97969 18.7778 9.88889Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </form>
      </div>
      <ng-container *ngIf="isUserAuth; else templateNoAuthDesk">
        <div class="flex items-center gap-[20px]">
          <div class="flex items-center gap-[7px]">
            <span class="flex cursor-pointer rounded-[50%] border-[2px] border-solid border-main-purple-400">
              <img ngSrc="assets/images/svg/icons/profile-icon.svg" alt="" height="64" width="64" />
            </span>
            <span class="flex cursor-pointer">
              <img ngSrc="assets/images/svg/icons/arrow-down-small.svg" alt="" height="10" width="15" />
            </span>
          </div>
          <div class="relative">
            <span class="flex cursor-pointer">
              <img ngSrc="assets/images/svg/icons/bell-icon.svg" alt="" height="28" width="26" />
            </span>
            <span
              class="absolute right-[-10px] top-[-8px] flex flex-col items-center justify-center rounded-[50%] text-center content-none before:absolute before:block before:h-[calc(100%+4px)] before:w-[calc(100%+4px)] before:rounded-[50%] before:bg-main-dark-blue-300">
              <span class="relative z-[2] flex h-[20px] w-[20px] flex-col items-center justify-center rounded-[50%] bg-main-purple-500 text-[12px] font-semibold leading-none text-white">12</span>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-template #templateNoAuthDesk>
        <a routerLink="/auth/registration" class="btn btn-primary min-w-[180px]">{{ 'header.buttons.register' | translate }}</a>
        <a routerLink="/auth/login" class="btn btn-text-primary ml-[14px] min-w-[128px]">{{ 'header.buttons.login' | translate }}</a>
      </ng-template>
    </div>

    <button (click)="openMobileMenu()" class="relative ml-12 h-[50px] w-[50px] min-w-[50px] rounded-md bg-main-purple-500 lg:hidden">
      <span class="absolute left-1/2 inline-block h-[3px] w-[34px] -translate-x-2/4 translate-y-[-11px] rounded-md bg-white"></span>
      <span class="absolute left-1/2 inline-block h-[3px] w-[34px] -translate-x-2/4 translate-y-[-1px] rounded-md bg-white"></span>
      <span class="absolute left-1/2 inline-block h-[3px] w-[34px] -translate-x-2/4 translate-y-[9px] rounded-md bg-white"></span>
    </button>
  </div>
</header>

<ng-template #mobileMenu>
  <div class="mobile-menu" [class.active]="isMobileMenu">
    <div class="flex items-center justify-between gap-[20px] border-b border-solid border-white border-opacity-20 px-[28px] py-[20px]">
      <a routerLink="/" (click)="closeMobileMenu()">
        <img src="assets/images/logo.png" alt="" />
      </a>
      <span (click)="closeMobileMenu()" class="flex h-[50px] w-[50px] items-center justify-center p-[5px] cursor-pointer">
        <img src="assets/images/svg/icons/close-icons.svg" alt="" />
      </span>
    </div>

    <div class="flex flex-col items-start justify-center px-[28px] pb-5 pt-[37px] md:items-center">
      <ng-container *ngIf="isUserAuth; else templateNoAuthDesk">
        <div class="flex items-center gap-[20px]">
          <div class="flex items-center gap-[7px]">
            <span class="flex cursor-pointer rounded-[50%] border-[2px] border-solid border-main-purple-400">
              <img ngSrc="assets/images/svg/icons/profile-icon.svg" alt="" height="64" width="64" />
            </span>
            <span class="flex cursor-pointer">
              <img ngSrc="assets/images/svg/icons/arrow-down-small.svg" alt="" height="10" width="15" />
            </span>
          </div>
          <div class="relative">
            <span class="flex cursor-pointer">
              <img ngSrc="assets/images/svg/icons/bell-icon.svg" alt="" height="28" width="26" />
            </span>
            <span
              class="absolute right-[-10px] top-[-8px] flex flex-col items-center justify-center rounded-[50%] text-center content-none before:absolute before:block before:h-[calc(100%+4px)] before:w-[calc(100%+4px)] before:rounded-[50%] before:bg-main-dark-blue-300">
              <span class="relative z-[2] flex h-[20px] w-[20px] flex-col items-center justify-center rounded-[50%] bg-main-purple-500 text-[12px] font-semibold leading-none text-white">12</span>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-template #templateNoAuthDesk>
        <div class="flex w-full flex-col gap-[14px] sm:flex-row">
          <a (click)="closeMobileMenu()" routerLink="/auth/registration" class="btn btn-primary w-full !max-w-none flex-1">{{ 'header.buttons.register' | translate }}</a>
          <a (click)="closeMobileMenu()" routerLink="/auth/login" class="btn btn-text-primary w-full !max-w-none flex-1">{{ 'header.buttons.login' | translate }}</a>
        </div>
      </ng-template>
      <form (ngSubmit)="(controlSearch.getRawValue() && controlSearch.getRawValue().length > 0) && searchRequest()" class="mt-[12px] flex w-full">
        <input [placeholder]="'header.search_placeholder' | translate" type="text" class="form-input-black input-search w-full px-[15px] border-main-purple-500 border"  [formControl]="controlSearch"/>
        <button type="submit" class="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-r-[4px] bg-main-purple-500">
          <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              d="M21 21L16.1667 16.1667M18.7778 9.88889C18.7778 14.7981 14.7981 18.7778 9.88889 18.7778C4.97969 18.7778 1 14.7981 1 9.88889C1 4.97969 4.97969 1 9.88889 1C14.7981 1 18.7778 4.97969 18.7778 9.88889Z"
              stroke="#ffffff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </form>
      <form class="lang-switch mt-[50px]">
        <mat-label class="mr-2">
          <img class="h-[18px] w-7 min-w-[28px]" [src]="'assets/images/flag-' + controlLang.getRawValue().toLowerCase() + '.png'" [alt]="controlLang.getRawValue()" />
        </mat-label>
        <mat-select class="" [formControl]="controlLang" [panelClass]="'lang-desk-panel-class'">
          <ng-container *ngFor="let lang of langList">
            <mat-option [value]="lang.value">
              <div class="flex items-center">
                <img class="mr-[7px] h-[18px] w-7 min-w-[28px]" [src]="'assets/images/flag-' + lang.value.toLowerCase() + '.png'" [alt]="lang.value" />
                <span class="text-sm leading-none text-white">{{ lang.viewValue }}</span>
              </div>
            </mat-option>
          </ng-container>
        </mat-select>
      </form>
      <ul class="mt-[50px] flex flex-col gap-[50px] md:items-center md:gap-[46px]">
        <li>
          <a (click)="closeMobileMenu()" (click)="closeMobileMenu()" routerLink="/" class="text-[24px] hover:text-main-purple-400">{{ 'header.home' | translate }}</a>
        </li>
        <li>
          <a (click)="closeMobileMenu()" routerLink="/faq" class="text-[24px] hover:text-main-purple-400">{{ 'header.faq' | translate }}</a>
        </li>
        <li>
          <a (click)="closeMobileMenu()" routerLink="/contacts" class="text-[24px] hover:text-main-purple-400">{{ 'header.contacts' | translate }}</a>
        </li>
      </ul>
      <ul class="socials mt-[44px] md:justify-center">
        <li>
          <a target="_blank" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.13055 0.159547C6.01431 0.255032 4.18006 0.772426 2.69894 2.24728C1.21265 3.72989 0.701684 5.5714 0.605961 7.66563C0.546457 8.97274 0.198512 18.8477 1.20748 21.4375C1.88789 23.1846 3.22797 24.5279 4.99108 25.2105C5.81378 25.5305 6.75292 25.7471 8.13055 25.8103C19.6496 26.3316 23.9196 26.0478 25.7202 21.4375C26.0397 20.6168 26.2596 19.6785 26.3204 18.3043C26.8469 6.7557 26.2351 4.25248 24.2275 2.24728C22.6351 0.658872 20.7621 -0.422396 8.13055 0.159547ZM8.2366 23.4877C6.97539 23.431 6.29111 23.2208 5.83449 23.044C4.68581 22.5976 3.82302 21.7383 3.37933 20.5964C2.61096 18.6286 2.8658 9.28265 2.93436 7.77036C3.00163 6.28904 3.30172 4.93522 4.34691 3.89005C5.64046 2.5997 7.31171 1.96731 18.6911 2.48088C20.1761 2.54797 21.533 2.84746 22.5808 3.89005C23.8743 5.18039 24.5159 6.8645 23.9933 18.2002C23.9364 19.4582 23.7256 20.1409 23.5484 20.5964C22.3777 23.5964 19.6845 24.0129 8.2366 23.4877ZM18.8166 6.09649C18.8166 6.9507 19.5112 7.64531 20.3688 7.64531C21.2265 7.64531 21.9224 6.9507 21.9224 6.09649C21.9224 5.24229 21.2265 4.5483 20.3688 4.5483C19.5112 4.5483 18.8166 5.24229 18.8166 6.09649ZM6.82147 12.9843C6.82147 16.6437 9.79535 19.6106 13.4639 19.6106C17.1324 19.6106 20.1062 16.6437 20.1062 12.9843C20.1062 9.3249 17.1324 6.35992 13.4639 6.35992C9.79535 6.35992 6.82147 9.3249 6.82147 12.9843ZM9.15245 12.9843C9.15245 10.6101 11.0824 8.68379 13.4639 8.68379C15.8453 8.68379 17.7753 10.6101 17.7753 12.9843C17.7753 15.3598 15.8453 17.2867 13.4639 17.2867C11.0824 17.2867 9.15245 15.3598 9.15245 12.9843Z"
                fill="" />
            </svg>
          </a>
        </li>
        <li>
          <a target="_blank" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.32129 20V11H10.0537L10.5 7H7.32129V5.05176C7.32129 4.02176 7.3476 3 8.7869 3H10.2447V0.140137C10.2447 0.0971367 8.99249 0 7.72568 0C5.07999 0 3.4234 1.6572 3.4234 4.7002V7H0.5V11H3.4234V20H7.32129Z"
                fill="" />
            </svg>
          </a>
        </li>
        <li>
          <a target="_blank" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.3625 20C17.7962 20 22.9562 12.3044 22.9562 5.63153C22.9562 5.41246 22.9562 5.19505 22.9412 4.97845C23.945 4.26464 24.8113 3.37836 25.5 2.36428C24.565 2.77287 23.5712 3.04082 22.555 3.15896C23.625 2.52762 24.4262 1.53574 24.81 0.365349C23.8025 0.953619 22.7012 1.3688 21.5525 1.59156C19.61 -0.441553 16.3612 -0.540058 14.295 1.37244C12.9637 2.60559 12.3975 4.44421 12.8112 6.19794C8.6875 5.99365 4.845 4.07626 2.24 0.921997C0.87875 3.22954 1.575 6.18044 3.82875 7.6622C3.0125 7.63881 2.21375 7.42244 1.5 7.03108V7.09517C1.50125 9.49873 3.2225 11.5683 5.615 12.0446C4.86 12.2476 4.0675 12.2771 3.3 12.1306C3.97125 14.1884 5.8975 15.5978 8.09125 15.6384C6.275 17.0438 4.03125 17.807 1.72125 17.8045C1.31375 17.8033 0.90625 17.7799 0.5 17.7319C2.84625 19.2137 5.575 20 8.3625 19.9963"
                fill="" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
