import { Component, OnDestroy, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Pagination, Autoplay } from 'swiper';
import { SocketClientService, SocketClientState } from 'src/app/core/services/socket.service';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { LastCompletedRequests } from 'src/app/api/fast-exchange/fast-exchange.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'app-remaining-transactions',
  templateUrl: './remaining-transactions.component.html',
  styleUrls: ['./remaining-transactions.component.scss'],
})
export class RemainingTransactionsComponent implements OnDestroy {
  @ViewChild('actualSwiper') swiper: SwiperComponent | undefined;
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      576: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1020: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  currentLang: string;
  lastCompletedRequests: LastCompletedRequests[] = [];
  loadingLastRequests = true;
  
  private listCompletedRequests$: Subscription = new Subscription;
  private unsubscribeAll$ = new Subject<boolean>();
  constructor(
    private socketClient: SocketClientService,
    private _translateService: TranslateService,
  ) {
    this.currentLang = this._translateService.currentLang;
    this._translateService.onLangChange.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (event: LangChangeEvent) => {
        this.currentLang = event.lang;
        this.socketListRequests();
      }
    });
    this.socketListRequests();
    this.socketClient.state.pipe(filter((state: any) => state === SocketClientState.ERROR), takeUntil(this.unsubscribeAll$)).subscribe(() => {
      this.socketListRequests();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next(true);
    this.unsubscribeAll$.complete();
    this.listCompletedRequests$ && this.listCompletedRequests$.unsubscribe();
  }

  private socketListRequests(): void {
    this.listCompletedRequests$ && this.listCompletedRequests$.unsubscribe();
    this.listCompletedRequests$ = this.socketClient.onPlainMessage(`/topic/lastCompleted.${this.currentLang}`).pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (response: string) => {
        this.lastCompletedRequests = JSON.parse(response);
        this.loadingLastRequests = false;
      }
    });
    this.socketClient.send(`/app/lastCompleted.${this.currentLang}`);
  }
}
