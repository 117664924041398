<section class="bg-default overflow-hidden py-[60px] lg:pb-[100px] lg:pt-[40px]">
  <div class="container xl:mt-[44px]">
    <h2 class="font-light mb-5 lg:mb-12 text-center" [innerHTML]="'home.recent_transaction' | translate"></h2>
    <ng-container *ngIf="lastCompletedRequests && !loadingLastRequests; else templateLoader">
      <ng-container *ngIf="lastCompletedRequests.length > 0; else temlpateNullElement">
        <swiper [pagination]="{ clickable: true }" [autoplay]="{ delay: 3000, disableOnInteraction: false }" [loop]="true" #actualSwiper [config]="config" class="swiper-transactions m-[-20px] p-[20px]">
          <ng-template swiperSlide *ngFor="let slide of lastCompletedRequests">
            <div class="flex select-none flex-col rounded-[12px] bg-main-dark-blue-500 px-[18px] py-[12px] shadow-slide">
              <div class="flex items-center justify-between gap-[8px] text-[12px] lg:text-[16px]">
                <span class="">
                  {{ slide.recent_time_description }}
                </span>
                <span class="text-white text-opacity-70">
                  {{ slide.execution_time_description }}
                </span>
              </div>
              <div class="mt-[18px] flex items-center justify-between gap-[5px] xl:mt-[24px]">
                <div class="flex items-center gap-[7px]">
                  <div class="grow-1 flex max-w-[max-content] mb-auto">
                    <img class="min-w-11" [ngSrc]="'assets/images/currency/' + (slide.from_type === 'FIAT' ? (slide.from_network.toLowerCase()) : (slide.from_symbol.toLowerCase())) + '.svg'" alt="" height="44" width="44" />
                  </div>
                  <div class="flex flex-col gap-[6px]">
                    <span class="text-[12px] lg:text-[16px] leading-none">{{ slide.from_amount }}</span>
                    <span class="text-[18px] font-bold uppercase leading-none text-white text-opacity-40 xl:text-[24px]">{{ slide.from_symbol }}</span>
                    <span class="text-xs leading-none">{{ slide.from_network | transformNetworkToDefaultType: slide.from_symbol }}</span>
                  </div>
                </div>
                <div class="flex mb-auto mt-[6px]">
                  <img ngSrc="assets/images/svg/icons/arrow-exchange.svg" alt="" height="28" width="28" />
                </div>
                <div class="flex items-center gap-[7px]">
                  <div class="grow-1 flex max-w-[max-content] mb-auto">
                    <img class="min-w-11" [ngSrc]="'assets/images/currency/' + (slide.to_type === 'FIAT' ? (slide.to_network.toLowerCase()) : (slide.to_symbol.toLowerCase())) + '.svg'" alt="" height="44" width="44" />
                  </div>
                  <div class="flex flex-col gap-[6px]">
                    <span class="text-[12px] lg:text-[16px] leading-none">{{ slide.to_amount}}</span>
                    <span class="text-[18px] font-bold uppercase leading-none text-white text-opacity-40 xl:text-[24px]">{{ slide.to_symbol }}</span>
                    <span class="text-xs leading-none">{{ slide.to_network | transformNetworkToDefaultType: slide.to_symbol }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </ng-container>
    </ng-container>
  </div>
</section>

<ng-template #temlpateNullElement>
  <div class="relative flex items-center justify-center min-h-[200px]">
    <div class="flex flex-col items-center mx-auto">
      <img class="mb-3 w-full" src="assets/images/svg/table-error.svg" alt="">
      <span class="text-[20px] text-white">{{ 'shared.empty_data' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #templateLoader>
  <div class="relative min-h-[220px]">
    <app-loader [sizeClass]="'block'"></app-loader>
  </div>
</ng-template>
