import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DirectionData, City, SupportMerchant, RateCommon, CommissionDataCommon, FERequest, GetDepositParams, DirectionsMerchantData } from './fast-exchange.model';
import {
  ParamsFEDirectionData,
  ParamsFELoadCity,
  ParamsFERate,
  ParamsFEValidateWallet,
  ParamsCommissionData,
  ParamsFECreate,
  ParamsFEGetDepositParams,
  ParamsFEValidateTag,
  ParamsFEDirection,
} from 'src/app/core/models/fast-exchange';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FastExchangeService {
  apiUrl = `${environment.apiUrl}api/`;

  constructor(private http: HttpClient) {}

  getDirectionsData(form: ParamsFEDirectionData): Observable<DirectionData[]> {
    let params = new HttpParams();
    form && form.from_currency_type && (params = params.append('from_currency_type', form.from_currency_type.toString()));
    form && form.to_currency_type && (params = params.append('to_currency_type', form.to_currency_type.toString()));
    return this.http.get<DirectionData[]>(`${this.apiUrl}fast-exchange/data`, { params });
  }

  putRevokeRequest(id: string | number): Observable<number> {
    let params = new HttpParams();
    params = params.append('exchange_request_id', id.toString());
    return this.http.put<number>(`${this.apiUrl}fast-exchange/revoke`, {}, { params });
  }

  getSupportedCities(form: ParamsFELoadCity): Observable<City[]> {
    let params = new HttpParams();
    form && form.from_code && (params = params.append('from_code', form.from_code.toString()));
    form && form.to_code && (params = params.append('to_code', form.to_code.toString()));
    return this.http.get<City[]>(`${this.apiUrl}fast-exchange/supported-cities`, { params });
  }

  getMerchant(): Observable<SupportMerchant[]> {
    return this.http.get<SupportMerchant[]>(`${this.apiUrl}merchant`);
  }

  getCurrentRate(form: ParamsFERate): Observable<RateCommon> {
    let params = new HttpParams();
    form && form.id && (params = params.append('exchange_direction_id', form.id.toString()));
    form && form.city_code && (params = params.append('city_code', form.city_code.toString()));
    return this.http.get<RateCommon>(`${this.apiUrl}fast-exchange/current-rate`, { params });
  }
  getValidateAddress(form: ParamsFEValidateWallet): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('address', form.address.toString());
    params = params.append('currency_symbol', form.symbol.toString());
    params = params.append('network', form.network.toString());
    return this.http.get<boolean>(`${this.apiUrl}fast-exchange/validate-address`, { params });
  }

  getValidateTagAddress(form: ParamsFEValidateTag): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('currency_symbol', form.currency_symbol.toString());
    params = params.append('network', form.network.toString());
    form.tag_address && (params = params.append('tag_address', form.tag_address.toString()));
    return this.http.get<boolean>(`${this.apiUrl}fast-exchange/validate-tag-address`, { params });
  }

  getCommissionData(form: ParamsCommissionData): Observable<CommissionDataCommon> {
    let params = new HttpParams();
    params = params.append('exchange_direction_id', form.id.toString());
    params = params.append('amount', form.amount.toString());
    params = params.append('is_reverse_amount', form.isReverseAmount.toString());
    params = params.append('rate_key', form.rate.toString());
    form?.city_code && (params = params.append('city_code', form.city_code.toString()));
    return this.http.get<CommissionDataCommon>(`${this.apiUrl}fast-exchange/commission-data`, { params });
  }

  postCreateRequest(body: ParamsFECreate): Observable<object> {
    return this.http.post(`${this.apiUrl}fast-exchange/request/create`, body);
  }

  getSearchInfo(val: string): Observable<FERequest> {
    let params = new HttpParams();
    params = params.append('exchange_request_number', val.toString());
    return this.http.get<FERequest>(`${this.apiUrl}fast-exchange/request/search-info`, { params });
  }

  putConfirmPayment(id: number): Observable<number> {
    let params = new HttpParams();
    params = params.append('exchange_request_id', id.toString());
    return this.http.put<number>(`${this.apiUrl}fast-exchange/confirm-payment`, {}, { params });
  }

  getRequestInfo(id: string): Observable<FERequest> {
    let params = new HttpParams();
    params = params.append('exchange_request_id', id.toString());
    return this.http.get<FERequest>(`${this.apiUrl}fast-exchange/request/info`, { params });
  }

  getDepositParams(form: ParamsFEGetDepositParams): Observable<GetDepositParams> {
    let params = new HttpParams();
    params = params.append('exchange_direction_id', form.exchange_direction_id.toString());
    params = params.append('amount', form.amount.toString());
    return this.http.get<GetDepositParams>(`${this.apiUrl}fast-exchange/deposit-params`, { params });
  }

  getDirectionIsPresent(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}fast-exchange/any-direction-is-present`);
  }

  getDepositConfirmations(exchange_direction_id: number): Observable<number> {
    let params = new HttpParams();
    params = params.append('exchange_direction_id', exchange_direction_id.toString());
    return this.http.get<number>(`${this.apiUrl}fast-exchange/deposit-confirmations`, { params });
  }

  getDirection(form: ParamsFEDirection): Observable<DirectionsMerchantData> {
    let params = new HttpParams();
    form.city_code && (params = params.append('city_code', form.city_code.toString()));
    params = params.append('exchange_direction_id', form.exchange_direction_id.toString());
    return this.http.get<DirectionsMerchantData>(`${this.apiUrl}fast-exchange/direction`, { params });
  }
}
