export enum TypeCommission {
  RECOMMENDED = 'RECOMMENDED',
  SERVICE = 'SERVICE',
  STANDARD = 'STANDARD',
}

export enum CommissionDataErrorCode {
  NOT_ERROR = 0,
  MIN_AMOUNT = 1,
  MAX_AMOUNT = 2,
  RESERVE_BALANCE = 3,
  COMMISSION_BIGGER = 4,
  COMMISSION_EQUEL = 5,
}

export enum TypeRate {
  FIXED = 'FIXED',
  FLOAT = 'FLOAT',
}

export enum RequestStatusFE {
  EXPIRED = 'EXPIRED',
  IN_PROCESS = 'IN_PROCESS',
  CREATED = 'CREATED',
  AUTO_REVOKED = 'AUTO_REVOKED',
  REVOKED_BY_ADMIN = 'REVOKED_BY_ADMIN',
  REVOKED_BY_ACCOUNT = 'REVOKED_BY_ACCOUNT',
}

export enum DepositStatusFE {
  EXPIRED = 'EXPIRED',
  IN_PROCESS = 'IN_PROCESS',
  CREATED = 'CREATED',
  AUTO_REVOKED = 'AUTO_REVOKED',
  REVOKED_BY_ADMIN = 'REVOKED_BY_ADMIN',
  REVOKED_BY_ACCOUNT = 'REVOKED_BY_ACCOUNT',
  PAID_UNCONFIRMED = 'PAID_UNCONFIRMED',
  COMPLETED = 'COMPLETED',
}

export enum WithdrawStatusFE {
  COMPLETED = 'COMPLETED',
  AUTO_REVOKED = 'AUTO_REVOKED',
  REVOKED_BY_ADMIN = 'REVOKED_BY_ADMIN',
  REVOKED_BY_ACCOUNT = 'REVOKED_BY_ACCOUNT',
}

export enum SubMerchant {
  CRYPTO = 'CRYPTO',
  CARD = 'CARD',
  FIAT = 'FIAT',
  CASH = 'CASH',
  BANK = 'BANK',
  MANUAL_CASH = 'MANUAL_CASH',
  MANUAL_CRYPTO = 'MANUAL_CRYPTO',
  MANUAL_CARD = 'MANUAL_CARD',
  MANUAL_FIAT = 'MANUAL_FIAT',
  MANUAL_BANK = 'MANUAL_BANK'
}

export enum CurrencyType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}

export enum InputParams {
  cash_city = 'cash_city',
  cash_city_code = 'cash_city_code',
  crypto_address = 'crypto_address',
  crypto_tag_address = 'crypto_tag_address',
  crypto_alias = 'crypto_alias',
  card_number = 'card_number',
  card_alias = 'card_alias',
  card_holder_name = 'card_holder_name',
  card_account_number = 'card_account_number',
  card_bank_name = 'card_bank_name',
  card_email = 'card_email',
  card_phone = 'card_phone',
  card_telegram = 'card_telegram',
  card_country = 'card_country',
  owner_card_number = 'owner_card_number',
  fiat_account = 'fiat_account',
  fiat_card_number = 'fiat_card_number',
  fiat_alias = 'fiat_alias',
  fiat_holder_name = 'fiat_holder_name',
  fiat_first_name = 'fiat_first_name',
  fiat_last_name = 'fiat_last_name',
  fiat_bank_name = 'fiat_bank_name',
  fiat_bank_code = 'fiat_bank_code',
  fiat_bank_account = 'fiat_bank_account',
  fiat_bank_card_no = 'fiat_bank_card_no',
  fiat_branch_name = 'fiat_branch_name',
  fiat_account_number = 'fiat_account_number',
  fiat_email = 'fiat_email',
  fiat_phone = 'fiat_phone',
  fiat_telegram = 'fiat_telegram',
  fiat_country = 'fiat_country',
  fiat_city = 'fiat_city',
  bank_alias = 'bank_alias',
  bank_holder_name = 'bank_holder_name',
  bank_name = 'bank_name',
  bank_code = 'bank_code',
  bank_account_number = 'bank_account_number',
  bank_email = 'bank_email',
  bank_phone = 'bank_phone',
  bank_telegram = 'bank_telegram'
}

export enum OperationType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
  Exchange = 'EXCHANGE',
  OTC_EXCHANGE = 'OTC_EXCHANGE',
  TRANSFER = 'TRANSFER',
  B2C_DEPOSIT = 'B2C_DEPOSIT',
}

export enum SourceType {
  ORGANIC = 'ORGANIC',
  BEST_CHANGE = 'BEST_CHANGE',
  OPEN_API_PARTNER = 'OPEN_API_PARTNER',
  AML_NODE_PARTNER = 'AML_NODE_PARTNER',
  TEST = 'TEST ',
}
